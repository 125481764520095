import { Link, graphql } from 'gatsby'
import React, { useState } from 'react'
import ReactPlayer from 'react-player/vimeo'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'

import CustomImg from '../components/global/CustomImg'
import Lightbox from '../components/global/Lightbox'
import ShareLinkButton from '../components/global/ShareLinkButton'
import PlayIcon from '../components/icons/PlayIcon'
import styles from './animation-gallery.module.scss'

const AnimationGallery = ({ data }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState('')
  const [videoShare, setVideoShare] = useState('')
  const [videoCaption, setVideoCaption] = useState('')
  const openVideo = (videoUrl, videoShareLink, animationCaption) => {
    setVideoPlaying(videoUrl)
    setVideoShare(videoShareLink)
    setVideoCaption(animationCaption)
    setLightboxOpen(true)
  }

  const [videoOpacity, setVideoOpacity] = useState('0')
  const handleVideoLoading = () => {
    setVideoOpacity('1')
  }

  return (
    <Layout>
      <SEO
        title="Animation Gallery"
        image={data.allStrapiAnimation.nodes[0].animation_still}
      />
      <TopNav />
      <section className={`backgroundBlack pt128 pb64`}>
        <div className={`pageMargin1Col mb64`}>
          <h1 className={`h2`}>Animations</h1>
          <p className={`mb24`}>
            Experience the stories of veterans and activists brought to life
            through short animations, ranging from firefights in the jungle to
            protests in Congress.
          </p>
          <p className={`h5 textColorFuzzy`}>Tap an animation to view</p>
        </div>
        <div
          className={`sectionBottomMarginSmall ${styles.animationsGrid} pageMargin1Col`}
        >
          {data.allStrapiAnimation.nodes.map(
            (
              {
                animation_title,
                animation_url,
                animation_still,
                animation_slug,
                animation_caption,
              },
              index
            ) => (
              // <div key={index}>
              <button
                style={{
                  padding: '0',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                // className={`${styles.playBtn}`}
                className={`textLeft`}
                type="button"
                onClick={() =>
                  openVideo(
                    animation_url,
                    `animation-gallery/${animation_slug}/`,
                    animation_caption
                  )
                }
                aria-label="Play Video"
                key={index}
              >
                <div className={`relPos mb16 ${styles.imageWrapper}`}>
                  <CustomImg
                    localFile={animation_still.localFile}
                    alt={animation_still.alt}
                  />
                  <div className={`${styles.playBtn}`}>
                    <PlayIcon />
                  </div>
                </div>
                <h2 className={`h5 ${styles.titleWrapper}`}>
                  {animation_title}
                </h2>
                <p className={`figcaption`}>{animation_caption}</p>
              </button>
              // </div>
            )
          )}
        </div>

        <div className={`pageMargin1Col mt64`}>
          <hr className={`mt32 mb32 backgroundSilver`} />
          <div
            className={`flexTabletUp justifyContentBetween textCenter ml32 mr32`}
          >
            <Link to={`/`}>
              <p className={`h5 mt16 mb16`}>Return to Home</p>
            </Link>

            <Link to={`/profile-cards/`}>
              <p className={`h5 mt16 mb16`}>Profile Cards</p>
            </Link>

            <Link to={`/illustration-gallery/`}>
              <p className={`h5 mt16 mb16`}>Illustrations</p>
            </Link>
          </div>
          <hr className={`mt32 backgroundSilver`} />
        </div>

        <Lightbox isVisible={lightboxOpen} setIsVisible={setLightboxOpen}>
          <ReactPlayer
            className={`lightboxImg ${styles.lightboxVideo}`}
            width="100%"
            // height="calc(100vh - 5rem)"
            // height="100%"
            height="calc(56.25vw)"
            url={videoPlaying}
            controls
            playsinline // Prevent Vimeo from automatically entering fullscreen
            playing={lightboxOpen}
            onReady={handleVideoLoading}
            style={{ opacity: videoOpacity }}
          />
          <p className={`h6 lightboxCaption ${styles.videoCaption}`}>
            {videoCaption}
          </p>
          <ShareLinkButton
            className={`${styles.shareBtn}`}
            linkPage={videoShare}
          />
        </Lightbox>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiAnimation(sort: { fields: section___section_code }) {
      nodes {
        animation_title
        animation_url
        animation_slug
        animation_caption
        animation_still {
          alternativeText
          caption
          localFile {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default AnimationGallery
